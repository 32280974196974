<template>
  <v-container>
      <v-dialog
      v-model="dialog"
      persistent
      max-width="500"
  >
      <v-card>
          <v-card-title class="text-h5">
              {{ $vuetify.lang.t('$vuetify.actions.suspend') }}
          </v-card-title>
          <v-card-text>
              <p>
                  {{$vuetify.lang.t('$vuetify.texts.suspend')}}
              </p>

              <v-form
                  ref="form"
                  v-model="formValid"
                  style="padding: 0"
                  lazy-validation
              >

                  <app-datetime-picker
                      v-model="suspend.suspendDate"
                      :min-date="new Date()"
                      :rules="formRule.required"
                      :clearText="$vuetify.lang.t('$vuetify.actions.cancel')"
                      :okText="$vuetify.lang.t('$vuetify.actions.accept')"
                      :label="$vuetify.lang.t('$vuetify.plan.ends_at')"
                  />
                  <v-textarea
                      v-model="suspend.description"
                      :label="$vuetify.lang.t('$vuetify.description')"
                      :rules="formRule.required"
                  ></v-textarea>
              </v-form>
          </v-card-text>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  class="mb-2"
                  text
                  color="red"
                  :loading="isActionInProgress"
                  @click="dialog = false"
              >
                  <v-icon>mdi-close-circle</v-icon>
                  {{$vuetify.lang.t('$vuetify.actions.cancel')}}
              </v-btn>

              <v-btn
                  class="mb-2"
                  color="primary"
                  :disabled="!formValid || isActionInProgress"
                  :loading="isActionInProgress"
                  @click="suspendAccountHandler"
              >
                  <v-icon>mdi-check-circle</v-icon>
                  {{ smallScreen ? '': $vuetify.lang.t('$vuetify.actions.save') }}
              </v-btn>
          </v-card-actions>
      </v-card>
  </v-dialog>
    <app-loading v-if="loading" />
    <v-row v-else>
      <v-col
        md="12"
        cols="12"
      >
        <app-data-table
          :title="
            $vuetify.lang.t('$vuetify.titles.list', [
              $vuetify.lang.t('$vuetify.menu.company_subscription')
            ])
          "
          csv-filename="Categories"
          :headers="getTableColumns"
          :items="companies"
          :accessCancelButton="true"
          :accessSuspendButton="true"
          :viewNewButton="false"
          :viewEditButton="false"
          :viewDeleteButton="false"
          :manager="'supplier'"
          :sort-by="['firstName']"
          :sort-desc="[false, true]"
          multi-sort
          @suspend-row="suspendCompany"
        >
            <template v-slot:[`item.nameCountry`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-avatar left>
                                {{ item.countryFlag }}
                            </v-avatar>
                            {{ item.country }}
                        </v-chip>
                    </template>
                    <span>{{ item.nameCountry }}</span>
                </v-tooltip>
            </template>
            <template v-slot:[`item.plan_name`]="{ item }">
                {{ item.plan.length > 0 ? item.plan[0].name : $vuetify.lang.t('$vuetify.no_defined') }}
            </template>
            <template v-slot:[`item.state_text`]="{ item }">
                {{ item.plan.length > 0 ? $vuetify.lang.t('$vuetify.subscription_state.' + item.state_text) : $vuetify.lang.t('$vuetify.no_defined') }}
            </template>
        </app-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  name: 'CompanySubscription',
  data () {
    return {
      suspend: {
        suspendDate: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
        description: '',
        status: 'suspend',
        company: null

      },
      formRule: this.$rules,
      formValid: false,
      dialog: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters('statics', ['smallScreen']),
    ...mapState('company', ['companies', 'isActionInProgress']),
    getTableColumns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.firstName'),
          value: 'name',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.email'),
          value: 'email'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.country'),
          value: 'nameCountry',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.phone'),
          value: 'phone',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.plan.name'),
          value: 'plan_name',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.state'),
          value: 'state_text',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.actions.actions'),
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  watch: {
    companies: function () {
    }
  },
  async created () {
    this.loading = true
    await this.getCompanies()
    this.loading = false
  },

  methods: {
    ...mapActions('company', [
      'getCompanies',
      'suspendAccount'
    ]),
    suspendCompany ($event) {
      this.suspend.company = $event
      this.dialog = true
    },
    async suspendAccountHandler () {
      await this.suspendAccount(this.suspend)
      await this.getCompanies()
    }

  }
}
</script>

<style scoped>

</style>
